button {
  padding: 0.8rem 1rem;
  width: auto;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-sizing: border-box;
  color: #d6e1f1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.7rem;
  font-family: "Montserrat";
  margin: 0.2rem;
  transition: var(--transition-normal);
  position: relative;
  background-image: var(--brand-gradient);
  z-index: 1;
}
button::before {
  position: absolute;
  content: "";
  border-radius: 0.7rem;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: var(--brand-gradient-highlight);
  z-index: -1;
  transition: opacity 0.5s linear;
  opacity: 0;
}
button:hover::before {
  opacity: 1;
}
button img {
  padding-right: 0.25rem;
  width: 1.2rem;
  height: 1.2rem;
}
