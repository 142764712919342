:host {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .query {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      width: 100%;
    }
  }
}
